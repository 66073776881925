import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Text } from '@nike/eds';
import { FormContainer } from 'components/FormContainer';
import { ViewMode } from 'lib/enums';
import Loading from 'components/Loading';
import PPIForm from 'components/forms/PPI';
import {
  useFetchPPIListQuery,
  usePatchPPIMutation,
} from 'features/adminApi/endpoints/ppi';
import { useToasts } from 'react-toast-notifications';

const EditPPI = () => {
  const { addToast } = useToasts();
  const { ppiHandle } = useParams();
  const history = useHistory();
  const { data = [], isLoading } = useFetchPPIListQuery();
  const ppiData = data.find((ppi) => ppi.handle === ppiHandle);
  const [patchPPI, patchPPIResult] = usePatchPPIMutation();

  const handleEditPPI = useCallback(
    async (values) => {
      const updatedValues = { ...values };
      patchPPI(updatedValues);
    },
    [ppiData]
  );

  const handleCancel = useCallback(async () => {
    history.push('/ppi');
  }, []);

  useEffect(() => {
    if (patchPPIResult.isSuccess) {
      addToast('PPI data updated successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.goBack();
    }
  }, [patchPPIResult.isSuccess]);

  return (
    <>
      { isLoading ? (
        <Loading text="Fetching PPI details" />
      ) : (
        <FormContainer>
          <Text font={ 'title-1' } as={ 'h1' }>
            Edit PPI
          </Text>
          <PPIForm
            onSubmit={ handleEditPPI }
            disabled={ isLoading || patchPPIResult.isLoading }
            initialValues={ ppiData }
            viewMode={ ViewMode.EDIT }
            submitText="Save PPI data"
            onCancel={ handleCancel }
          />
        </FormContainer>
      ) }
    </>
  );
};

export default EditPPI;
