import { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import renderField, { getFieldType } from './utils';
import { isEmpty } from 'lodash';
import { fieldValueTypes, fieldsTypes, formTypes } from 'lib/propTypes';
import PropTypes from 'prop-types';
import { QuestionSetTypes } from './RepeatableFieldsetUtils';

const Fields = ({
  fields,
  formState,
  errors,
  handleChange,
  existingPPI,
  layoutType,
  isApproved,
  choiceConditionMet,
  disableCategoryChoice,
  requiredFields,
  initialValues,
  setFieldValue,
}) => {
  const [showCategoryContent, setShowCategoryContent] = useState(false);

  //Filter the fields based on the category-choice option selected
  const modifiedFields = useMemo(
    () =>
      (choiceConditionMet
        ? fields.length > 2
          ? fields?.filter((item) => item.sortOrder <= 7)
          : [] //In-case answer-options exists remove them
        : fields),
    [fields]
  );

  const { values } = useFormikContext();

  return !isEmpty(modifiedFields)
    ? modifiedFields
      .filter(
        (field) =>
          !['image', 'repeatingText', 'questionSet'].includes(
            getFieldType(field)
          )
      )
      .map(
        ({
          field,
          type,
          value,
          maxChars,
          label,
          options,
          isCreatable,
          activeId,
          message,
          disabled,
          className,
        }) => {
          const updateDisableProp = field?.includes(
            QuestionSetTypes.CATEGORY_CHOICE
          )
            ? disableCategoryChoice
            : disabled;

          return renderField(
            {
              field,
              type,
              value,
              maxChars,
              label,
              options,
              isCreatable,
              activeId,
              message,
              existingPPI,
              layoutType,
              formState,
              values,
              initialValues,
              setFieldValue,
              className,
              disabled: updateDisableProp,
              showCategoryContent,
              setShowCategoryContent,
            },
            handleChange,
            errors,
            disabled,
            isApproved,
            requiredFields
          );
        }
      )
    : null;
};

Fields.propTypes = {
  ...formTypes,
  fields: PropTypes.oneOfType([
    fieldsTypes.consentData,
    fieldsTypes.contentData,
    fieldValueTypes,
  ]),
};

export default Fields;
