import PropTypes, { object } from 'prop-types';

export const arrayOfObjects = PropTypes.arrayOf(PropTypes.shape({}));
export const boolean = PropTypes.bool;
export const functionType = PropTypes.func;
export const array = PropTypes.array;
export const objectOrArray = PropTypes.oneOfType([arrayOfObjects, object]);
export const string = PropTypes.string;

export const selectOptionPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  group: PropTypes.string,
});

export const formTypes = {
  fields: arrayOfObjects,
  formState: objectOrArray,
  initialValues: objectOrArray,
  setFieldValue: functionType,
  setFormState: functionType,
  disabled: boolean,
  errors: object,
};

export const initialValuesTypes = {
  initialInquiryData: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      fieldSetId: PropTypes.number,
      sortOrder: PropTypes.number,
      type: PropTypes.string,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          className: PropTypes.string,
          // Initially is boolean and the updates to string if the question exists
          disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
          field: PropTypes.string,
          label: PropTypes.string,
          ppiHandle: PropTypes.string,
          isCreatable: PropTypes.bool,
          options: PropTypes.oneOfType([
            PropTypes.arrayOf(selectOptionPropType), // Can be an empty array
            selectOptionPropType,
          ]),
          sortOrder: PropTypes.number,
          type: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            selectOptionPropType,
            PropTypes.arrayOf(selectOptionPropType),
            PropTypes.arrayOf(
              PropTypes.shape({
                description: PropTypes.string,
                id: PropTypes.number,
                isReject: PropTypes.bool,
                label: PropTypes.string,
                value: PropTypes.string,
              })
            ),
            arrayOfObjects,
            PropTypes.oneOf([undefined, null]),
          ]),
        })
      ),
    })
  ),
  initialConsentData: PropTypes.shape({
    id: PropTypes.number,
    initiativeHandle: PropTypes.string,
    repeatingText: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.number,
        helperText: PropTypes.string,
        sortOrder: PropTypes.number,
        type: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  initialContentData: PropTypes.shape({
    backgroundImage: PropTypes.string,
    ctaText: PropTypes.string,
    id: PropTypes.number,
    initiativeHandle: PropTypes.string,
    logoImage: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
  }),
};

export const fieldValueTypes = PropTypes.arrayOf(
  PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.oneOfType([
      selectOptionPropType,
      PropTypes.arrayOf(selectOptionPropType),
    ]),
    sortOrder: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      arrayOfObjects,
      PropTypes.oneOf([undefined]),
    ]),
  })
);

export const fieldsTypes = {
  consentData: PropTypes.arrayOf(
    PropTypes.shape({
      activelayoutId: PropTypes.string,
      field: PropTypes.string,
      message: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.number,
          helperText: PropTypes.string,
          sortOrder: PropTypes.number,
          type: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  contentData: PropTypes.arrayOf(
    PropTypes.shape({
      activelayoutId: PropTypes.string,
      field: PropTypes.string,
      maxChars: PropTypes.string,
      message: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  inquiryData: PropTypes.shape({
    field: PropTypes.string,
    fieldSetId: PropTypes.number,
    sortOrder: PropTypes.number,
    type: PropTypes.string,
    value: fieldValueTypes,
  }),
};
