/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
// modules
import React, { useState, memo, useCallback, useMemo, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CssBaseline, Container } from '@material-ui/core';
import ErrorBoundary from 'components/ErrorBoundary';
import Theme from 'components/Theme';
import { pipe, toLower, split } from 'ramda';
// Components
import AppIcon from 'components/AppIcon';
import Breadcrumbs from 'components/Breadcrumbs';
import styles from './index.module.scss';
import {
  LayoutContainer,
  BreadcrumbsMenuContainer,
  GlobalLayoutContainer,
  RightLayoutContainer,
} from './SubNavigationMenu/styles';
import SubNavigationMenu from './SubNavigationMenu/SubNavigationMenu';
import { EntityType, ViewMode } from 'lib/enums';
import { VerticalNavbar, setActiveValueInNavItems } from '@nike/eds';
import { navPanelItems } from './SubNavigationMenu/subnavUtils';
import { userEntityPermissions } from 'components/RBAC';
import { ToastProvider } from 'react-toast-notifications';

// eslint-disable-next-line  complexity, max-statements
export default memo(({ children, navItems }) => {
  const history = useHistory();
  const path = history?.location.pathname ?? '';

  const [allNavItems, setAllNavItems] = useState(navItems);

  //show subnav when entity = initiative
  //But not when viewMode = CREATE
  const pathInformation = useMemo(() => {
    const parsePath = pipe(toLower, split('/'), (path) => {
      const entity = path[1];
      const initiativeType = path[2];
      const viewMode = path[3];
      const initiativeHandle = path[4] ? path[4] : path[3];

      return {
        entity,
        initiativeType,
        viewMode,
        initiativeHandle,
      };
    });

    const pathIdentifier = parsePath(path);
    return pathIdentifier;
  }, [path]);

  const handleNavigate = useCallback(
    (data) => {
      const newItems = setActiveValueInNavItems(data.id, allNavItems);
      setAllNavItems(newItems);
      history.push(`/${ data.value }`);
    },
    [history]
  );
  const handleLogoClick = useCallback(() => history.push('/'), [history]);

  const withPermissions = (navItems) =>
    navItems.filter((nav) => userEntityPermissions(nav.value));

  const isPreviewMode = ViewMode.PREVIEW && window?.previewData;
  const isOverrideApiMode = JSON.parse(sessionStorage.getItem('adminApiHost'));
  const showSubNavMenu = () => {
    return (
      pathInformation.entity === EntityType.INITIATIVE &&
      pathInformation.viewMode !== ViewMode.CREATE.toLowerCase() &&
      !!(
        pathInformation.initiativeHandle &&
        pathInformation.initiativeType &&
        pathInformation.viewMode
      )
    );
  };

  useEffect(() => {
    setAllNavItems((prevItems) => {
      return prevItems.map((item) => ({
        ...item,
        active: history.location.pathname === '/' ? false : item.active,
        subItems: item.subItems
          ? item.subItems.map((subItem) => ({
            ...subItem,
            active:
                history.location.pathname === '/' ? false : subItem.active,
          }))
          : item.subItems,
      }));
    });
  }, [history.location.pathname]);

  const menuContainerRef = useRef(null);

  useEffect(() => {
    if (menuContainerRef.current) {
      setTimeout(() => {
        menuContainerRef.current.scrollIntoView({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [history.location.pathname]);

  const initiativeType = pathInformation?.initiativeType;
  return (
    <ErrorBoundary className={ styles.layoutErrorBoundary }>
      <Theme theme={ 'light' }>
        <CssBaseline />
        <LayoutContainer isPreviewMode={ isPreviewMode }>
          { isOverrideApiMode && (
            <div className={ styles.override }>
              Overriding API Endpoint with { isOverrideApiMode }
            </div>
          ) }
          <GlobalLayoutContainer>
            <VerticalNavbar
              navLogoSlot={ <></> }
              panelLogoSlot={
                <AppIcon
                  onClick={
                    !isPreviewMode ? handleLogoClick : useCallback(() => {})
                  }
                />
              }
              appName="Jtl Admin Console"
              onNavigate={ handleNavigate }
              items={
                !isPreviewMode
                  ? [
                    {
                      ...navPanelItems[0],
                      subItems: withPermissions(navPanelItems[0].subItems),
                    },
                    ...navPanelItems.slice(1),
                    ...withPermissions(allNavItems),
                  ]
                  : []
              }
            />
            <RightLayoutContainer>
              { !isPreviewMode && (
                <BreadcrumbsMenuContainer ref={ menuContainerRef }>
                  <Container>
                    <Breadcrumbs path={ path } />
                  </Container>
                  { showSubNavMenu && (
                    <ToastProvider placement="bottom-right">
                      <SubNavigationMenu initiativeType={ initiativeType } />
                    </ToastProvider>
                  ) }
                </BreadcrumbsMenuContainer>
              ) }
              <div>{ children }</div>
            </RightLayoutContainer>
          </GlobalLayoutContainer>
        </LayoutContainer>
      </Theme>
    </ErrorBoundary>
  );
});
