import React from 'react';
import { Container } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import NotFound from 'components/NotFound';
import PPIListView from './components/List';
import CreatePPI from './components/Create';
import ViewPPI from './components/View';
import EditPPI from './components/Edit';
import { ViewMode } from 'lib/enums';
import withRolePermissions from 'lib/hooks/withRolePermissions';
import withEntityPermissions from 'lib/hooks/withEntityPermissions';

const PPI = () => {
  return (
    <Container>
      <Switch>
        <Route
          path="/:entity"
          exact={ true }
          component={ withRolePermissions(PPIListView) }
        />
        <Route
          path="/:entity/create"
          exact={ true }
          component={ withEntityPermissions(CreatePPI, ViewMode.CREATE) }
        />
        <Route
          path="/:entity/:ppiHandle"
          exact={ true }
          component={ withEntityPermissions(ViewPPI, ViewMode.READ) }
        />
        <Route
          path="/:entity/edit/:ppiHandle"
          exact={ true }
          component={ withEntityPermissions(EditPPI, ViewMode.EDIT) }
        />
        <Route component={ NotFound } />
      </Switch>
    </Container>
  );
};

export default PPI;
