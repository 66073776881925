import * as Yup from 'yup';
import { nonSpecialCharRegex } from '../utils';
import { startCase } from 'lodash';

export const PPI_TYPES = ['string', 'boolean', 'number', 'array', 'date'];

export const PPI_TYPE_OPTIONS = PPI_TYPES.map((ppiType) => ({
  label: startCase(ppiType),
  value: ppiType,
}));

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(nonSpecialCharRegex, 'Invalid character/s')
    .required('Required'),
  handle: Yup.string()
    .matches(nonSpecialCharRegex, 'Invalid character/s')
    .required('Required'),
  description: Yup.string().nullable()
    .required('Required'),
  ppiType: Yup.string()
    .required('Required')
    .oneOf(PPI_TYPES, 'Invalid PPI type'),
});

export const INITIAL_VALUES = {
  name: '',
  handle: '',
  description: '',
  ppiType: PPI_TYPES[0],
};
