/* eslint-disable max-statements */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AddEditForm } from '../components';
import { useHistory, useParams } from 'react-router-dom';
import {
  getFormFields,
  convertHandleToCardType,
} from 'components/forms/Assets/lib';
import Loading from 'components/Loading';
import {
  checkAndUpdateScreenerFields,
  formatInquiryDataForAsset,
  SCREENER_FORM,
} from 'lib/layoutUtils';
import {
  useFetchScreenerQuery,
  useCreateScreenerMutation,
  usePatchScreenerMutation,
  useFetchScreenerByIdQuery,
} from 'features/adminApi/endpoints/screener';
import { isEmpty } from 'lodash';
import { usePPIlist } from 'lib/hooks/usePPIlist.js';
import { useFetchConfigQuery } from 'features/adminApi/endpoints/config';
import { PPI_ANSWER_OPTIONS } from 'lib/configs';
import { convertLayoutId, filteredAsset } from '../utils';
import { ViewMode } from 'lib/enums';

const AddEditScreener = () => {
  const { id, type, viewMode } = useParams();
  const history = useHistory();
  const [layoutId, setlayoutId] = useState();
  const [formFields, setFormFields] = useState([]);
  const [initialData, setInitialData] = useState({});
  const [isApproved, setIsApproved] = useState(true);
  //const [preview, setPreview] = useState({ show: true });

  const { data, isLoading } = useFetchScreenerQuery(id);
  const [usePatchScreener, patchScreenerResult] = usePatchScreenerMutation();
  const [useCreateScreener, postScreenerResult] = useCreateScreenerMutation();
  const { data: screenerData, isLoading: isScreenerLoading } =
    useFetchScreenerByIdQuery(
      { initiativeHandle: id, id: layoutId },
      {
        skip:
          !id ||
          !layoutId ||
          type !== SCREENER_FORM ||
          viewMode === ViewMode.CREATE,
      }
    );

  const ppiList = usePPIlist();
  const { data: { value: configData } = {}, isFetching } =
    useFetchConfigQuery(PPI_ANSWER_OPTIONS);

  if (!isFetching && configData) {
    window.localStorage.setItem(
      'ppi-answer-options',
      JSON.stringify(configData)
    );
  }

  useEffect(() => {
    const initialLayoutId = history?.location?.state?.layoutId
      ? history?.location?.state?.layoutId
      : JSON.parse(sessionStorage.getItem('layoutId')) || initialData.id;
    const finalLayoutId = convertLayoutId(initialLayoutId);
    setlayoutId(finalLayoutId);
  }, [history, initialData]);

  useEffect(() => {
    const selectedData = screenerData
      ? screenerData.inquiryType === SCREENER_FORM && screenerData
      : layoutId && data
        ? data.filter((itm) => itm.id === layoutId).pop()
        : [];

    const { isAutomated } = selectedData?.attributes ?? {};

    const transformedData =
      !isEmpty(selectedData) &&
      formatInquiryDataForAsset(selectedData, SCREENER_FORM);

    const formFields =
      getFormFields(transformedData, 'screener', ppiList).elements[0].value ||
      [];

    const initialData = JSON.parse(JSON.stringify(formFields));

    setFormFields(formFields);
    setInitialData(initialData);
    setIsApproved(isAutomated);
  }, [data, layoutId, ppiList, screenerData, viewMode]);

  //TODO uncomment if add a preview
  //   useEffect(() => {
  //     const prev = { ...preview, questionSet: initialData.questionSet };
  //     if (!isEmpty(prev?.questionSet)) setPreview(prev);
  //   }, [initialData?.questionSet]);

  const layoutType = convertHandleToCardType(SCREENER_FORM);

  const handleIsApproved = useCallback(() => {
    setIsApproved(!isApproved);
  }, [isApproved]);

  const finalData = useMemo(
    () =>
      (viewMode !== ViewMode.CREATE ? filteredAsset(data, screenerData) : []),
    [data, screenerData]
  );

  return isLoading || isScreenerLoading ? (
    <Loading show={ true } text={ 'Fetching Screener Data' } />
  ) : (
    <AddEditForm
      layoutId={ layoutId }
      submitText={ 'Save Screener Form' }
      patchResult={ patchScreenerResult }
      postResult={ postScreenerResult }
      useCreate={ useCreateScreener }
      usePatch={ usePatchScreener }
      viewMode={ viewMode }
      data={ finalData }
      id={ id }
      //setPreview={ setPreview }
      initialData={ initialData }
      assetType={ SCREENER_FORM }
      layoutType={ layoutType }
      formFields={ formFields }
      history={ history }
      updateFields={ checkAndUpdateScreenerFields }
      initiativeHandle={ id }
      isApproved={ isApproved }
      handleIsApproved={ handleIsApproved }
    />
  );
};

export default AddEditScreener;
