import React, { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { propTypes } from 'lib/react';
import { Grid } from '@material-ui/core';
import { useFormikContext, withFormik } from 'formik';
import { TextField, SelectField } from 'components/fields';
import { MaskedTextField } from 'components/fields/MaskedTextField';
import { Button, Text } from '@nike/eds';
import { applyTo, pipe, isEmpty, pick } from 'ramda';
import { isNil } from 'lodash';
import { INITIAL_VALUES, PPI_TYPE_OPTIONS, validationSchema } from './lib';
import { trimAndReplace, ppiPrefix, creationFieldWarning } from '../utils';
import { getRequiredFields } from 'utils';
import { ViewMode } from 'lib/enums';
import styles from './index.module.scss';

const requestFields = ['name', 'handle', 'description', 'ppiType'];

export default applyTo(
  ({ handleSubmit, viewMode, onSubmit }) => {
    const history = useHistory();
    const disabled = viewMode === ViewMode.READ;
    const createPage = viewMode === ViewMode.CREATE;
    const { setFieldTouched, setFieldValue, touched, values, dirty, errors } =
      useFormikContext();

    useEffect(() => {
      const value = trimAndReplace(values?.name);
      if (!isNil(value) && createPage) setFieldValue('handle', value);
    }, [values?.name]);

    const handleCancel = useCallback((event) => {
      event.preventDefault();
      history.goBack();
    }, []);

    const handleChange = useCallback(
      (event) => {
        const key = event.target?.name;
        const value = event.target?.value;
        setFieldTouched(key, true);
        setFieldValue(key, trimAndReplace(value));
      },
      [setFieldTouched, setFieldValue]
    );

    const requiredFields = getRequiredFields(validationSchema);

    const handleFormSubmit = useCallback(async (event) => {
      event.preventDefault();
      await handleSubmit();
    }, []);

    return (
      <form onSubmit={ handleFormSubmit } encType="multipart/form-data">
        <fieldset disabled={ !!disabled } data-test-id="ppi-fieldset">
          <>
            <Grid item={ true } xs={ 12 }>
              <Text font="title-6" as="h6" className={ styles.title }>
                PPI Fields
              </Text>
            </Grid>
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 6 }>
                <TextField
                  name="name"
                  label="Name"
                  required={ requiredFields.name }
                  disabled={ disabled }
                />
              </Grid>
              <Grid item={ true } xs={ 6 }>
                <MaskedTextField
                  name="handle"
                  label="PPI handle"
                  onChange={ handleChange }
                  handleType={ ppiPrefix }
                  createPage={ createPage }
                  helperText={ creationFieldWarning }
                  value={
                    !createPage
                      ? values?.handle
                      : values?.handle || touched?.handle
                        ? values?.handle
                        : trimAndReplace(values?.name)
                  }
                  disabled={ !createPage || disabled }
                  required={ requiredFields.handle }
                  className={ !createPage || disabled ? styles.greyOut : '' }
                />
              </Grid>
            </Grid>
            <Grid container={ true } spacing={ 3 }>
              <Grid item={ true } xs={ 12 }>
                <TextField
                  name="description"
                  label="Description"
                  disabled={ disabled }
                  required={ requiredFields.description }
                />
              </Grid>
              <Grid item={ true } xs={ 12 }>
                <SelectField
                  name="ppiType"
                  label="PPI Type"
                  options={ PPI_TYPE_OPTIONS }
                  disabled={ disabled }
                  required={ requiredFields.ppiType }
                />
              </Grid>
              { /*{ !createPage && (
                <Grid item={ true } xs={ 12 }>
                  <SelectField
                    name="tagGroup"
                    label="Tag Group"
                    multiple={ true }
                    options={ [] }
                    disabled={ disabled }
                  />
                </Grid>
              ) }*/ }
            </Grid>
          </>
        </fieldset>
        <Grid item={ true } className={ styles.btnGroup }>
          <Button
            data-test-id="ppi-form-cancel-btn"
            variant="secondary"
            onClick={ handleCancel }
          >
            Cancel
          </Button>
          { !disabled ? (
            <Button
              data-test-id="ppi-form-submit-btn"
              variant="primary"
              disabled={ !isEmpty(errors) || !dirty || isEmpty(touched) }
              type="submit"
            >
              Submit
            </Button>
          ) : (
            <Button
              data-test-id="ppi-form-edit-btn"
              variant="secondary"
              onClick={ onSubmit }
              type="button"
            >
              Edit
            </Button>
          ) }
        </Grid>
      </form>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) =>
        (isEmpty(initialValues) ? INITIAL_VALUES : initialValues),
      validationSchema,
      handleSubmit: (
        values,
        { setSubmitting, props: { onSubmit, viewMode }, resetForm }
      ) => {
        const request = pick(requestFields, values);
        const { handle } = request;
        const ppiHandle =
          (viewMode === ViewMode.CREATE && `ppi-${ handle }`) || handle;
        const modifiedRequest = { ...request, handle: ppiHandle };
        onSubmit(modifiedRequest);
        resetForm();
        setSubmitting(false);
      },
      displayName: 'PPIForm',
    }),
    propTypes({
      onSubmit: PropTypes.func,
      handleSubmit: PropTypes.func,
      viewMode: PropTypes.string,
    }),
    memo
  )
);
