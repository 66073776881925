import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Text } from '@nike/eds';
import { FormContainer } from 'components/FormContainer';
import { ViewMode } from 'lib/enums';
import Loading from 'components/Loading';
import PPIForm from 'components/forms/PPI';
import { useFetchPPIListQuery } from 'features/adminApi/endpoints/ppi';

const ViewPPI = () => {
  const { ppiHandle } = useParams();
  const history = useHistory();
  const { data = [], isLoading } = useFetchPPIListQuery();
  const ppiData = data.find((ppi) => ppi.handle === ppiHandle);

  const handleEditPPI = useCallback(async () => {
    history.push(`/ppi/edit/${ ppiHandle }`);
  }, [ppiHandle]);

  return (
    <>
      { isLoading ? (
        <Loading text="Fetching PPI details" />
      ) : (
        <FormContainer>
          <Text font={ 'title-1' } as={ 'h1' }>
            View PPI
          </Text>
          <PPIForm
            onSubmit={ handleEditPPI }
            disabled={ isLoading }
            initialValues={ ppiData }
            viewMode={ ViewMode.READ }
          />
        </FormContainer>
      ) }
    </>
  );
};

export default ViewPPI;
