export const sanitizeRichText = (value) => {
  if (!value || typeof value !== 'string') {
    return null;
  }
  const trimmedValue = value.trim();
  if (
    trimmedValue === '\\' ||
    trimmedValue === '\\n' ||
    trimmedValue === '\\\n' ||
    trimmedValue === ''
  ) {
    return null;
  }
  return trimmedValue;
};
