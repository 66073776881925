import React from 'react';
import styles from '../../index.module.scss';
import { Text } from '@nike/eds';
import { SCREENER_FORM } from 'lib/layoutUtils';

export const renderOptionHeaders = ({
  layoutType,
  isCorePPI,
  isApproved,
  existingPPI,
  isTagsInputShown,
}) => (
  <div className={ styles.answerOptions }>
    { isCorePPI && (
      <Text className="eds-type--title-6 eds-spacing--mb-4">
        Answer options
      </Text>
    ) }
    <div
      className={
        isCorePPI
          ? styles.toggleNonCore
          : layoutType === SCREENER_FORM && !isApproved
            ? styles.toggleScreenerHeader
            : styles.toggleSurveyHeader
      }
    >
      { isApproved && <span className="eds-type--subtitle-2">Accept</span> }
      { !existingPPI && !isTagsInputShown && (
        <span className="eds-type--subtitle-2">Delete</span>
      ) }
    </div>
  </div>
);
