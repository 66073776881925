import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../index.module.scss';
import { selectionTypeOptions } from 'components/forms/utils';

export const ConditionalSelectSubtitle = ({
  fieldName,
  selectionType,
  onChange,
  disabled,
}) => {
  const selectId = `selectionType-${ fieldName?.split('-')[1] }`;
  return (
    <div className={ styles.conditionalSelectSubtitle }>
      <span>{ 'This question will only appear when ' }</span>
      <select
        id={ selectId }
        name={ selectId }
        onChange={ onChange }
        value={ selectionType }
        data-testid="selectionType"
        disabled={ disabled }
      >
        { selectionTypeOptions.map((option) => (
          <option key={ option.label } value={ option.value }>
            { option.label }
          </option>
        )) }
      </select>
      <span>{ ' of the below answers are selected' }</span>
    </div>
  );
};

ConditionalSelectSubtitle.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  selectionType: PropTypes.string,
};
