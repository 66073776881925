import React, { useCallback } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';
import { TextField } from '@nike/eds';

export const OptionInputField = ({
  id,
  label,
  value,
  fieldName,
  onChange,
  answerOption,
  existingPPI,
  setAnswerOption,
  answerOptionError,
  ppiHandle,
}) => {
  const handleTextFieldChange = useCallback(
    (event) => {
      const textValue = event.target.value;

      const options = answerOption?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            label: textValue,
            value: textValue,
            isDuplicate: false,
          };
        }
        return item;
      });
      onChange({ target: { name: fieldName, ppiHandle } }, options);
      setAnswerOption(options);
    },
    [answerOption]
  );

  return (
    <Field key={ 'field-' + id } name={ id } value={ existingPPI ? label : value }>
      { ({ field, meta, form }) => {
        const errorMessage =
          typeof answerOptionError === 'string' &&
          answerOptionError?.split(': ')[1]?.trim();

        const handleChange = useCallback(
          (event) => {
            form.setFieldTouched(field.name, true);
            handleTextFieldChange(event);
          },
          [answerOption]
        );

        return (
          <TextField
            id={ 'text-' + id }
            name={ label }
            label={ '' }
            value={ existingPPI ? label : value }
            disabled={ !!existingPPI }
            onChange={ handleChange }
            className={ styles.textFieldStyle }
            placeholder={ 'Enter Answer Option' }
            hasErrors={
              answerOptionError
                ? meta.touched && !value.trim()
                  ? answerOptionError
                  : errorMessage?.toLowerCase() === value.toLowerCase()
                : ''
            }
            errorMessage={ answerOptionError }
          />
        );
      } }
    </Field>
  );
};

OptionInputField.propTypes = {
  answerOption: PropTypes.arrayOf(Object),
  answerOptionError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  existingPPI: PropTypes.string,
  fieldName: PropTypes.string,
  id: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  ppiHandle: PropTypes.string,
  setAnswerOption: PropTypes.func,
  setTouched: PropTypes.func,
  value: PropTypes.string,
};
