import React from 'react';
import DataTable from 'components/DataTable';
import { columns, actions } from './tableConfig';
import { useFetchPPIListQuery } from 'features/adminApi/endpoints/ppi';

const PPIListView = () => {
  const { data = [], isLoading, isFetching } = useFetchPPIListQuery();

  return (
    <DataTable
      loading={ isLoading || isFetching }
      heading="PPIs"
      createHref="/ppi/create"
      createLabel="Create PPI"
      columns={ columns }
      actions={ actions }
      rows={ data || [] }
    />
  );
};

export default PPIListView;
