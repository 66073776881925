import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Plus } from '@nike/nike-design-system-icons';
import { getRandomInt } from 'lib/layoutUtils';
import styles from '../styles.module.scss';
import { Button } from '@nike/eds';

export const AddAnswerOption = ({
  existingPPI,
  isApproved,
  fieldName,
  disabled,
  onChange,
  setAnswerOption,
}) => {
  //eslint-disable-next-line max-statements
  const handleAddAnswerOption = useCallback(() => {
    const id = getRandomInt();
    const newAnswerOption = {
      id,
      label: '',
      value: '',
      isReject: isApproved,
    };

    setAnswerOption((prevAnswerOptions) => {
      const updatedAnswerOptions = [...prevAnswerOptions, newAnswerOption];
      // since we are updating the state(repeatableFieldSet) of parent component using onChange function
      // we need to wait for the state to update
      setTimeout(() => {
        onChange({ target: { name: fieldName } }, updatedAnswerOptions);
      }, 0);
      return updatedAnswerOptions;
    });
  }, [isApproved, fieldName, onChange, setAnswerOption]);

  return (
    <div className="eds-spacing--mt-24">
      { !existingPPI && (
        <Button
          size="small"
          variant="secondary"
          onClick={ handleAddAnswerOption }
          icon="Close"
          afterSlot={ <Plus /> }
          disableRipple={ false }
          className={ styles.buttonStyle }
          disabled={ disabled }
        >
          Add Answer Option
        </Button>
      ) }
    </div>
  );
};

AddAnswerOption.propTypes = {
  disabled: PropTypes.bool,
  existingPPI: PropTypes.string,
  fieldName: PropTypes.string,
  isApproved: PropTypes.bool,
  onChange: PropTypes.func,
  setAnswerOption: PropTypes.func,
};
