import { adminApiSlice } from '../adminApiSlice';
import { pathOr } from 'ramda';

const ppiApiSlice = adminApiSlice
  .enhanceEndpoints({
    addTagTypes: ['ppi'],
  })
  .injectEndpoints({
    endpoints(builder) {
      return {
        fetchPPIList: builder.query({
          query() {
            return '/ppis';
          },
          providesTags: () => [{ type: 'ppi', id: 'LIST' }],
          transformResponse: (response) => pathOr([], ['ppis'], response),
        }),
        patchPPI: builder.mutation({
          query: (body) => {
            return {
              url: `/ppis/${ body.handle }`,
              method: 'PATCH',
              body,
            };
          },
          invalidatesTags: () => [{ type: 'ppi', id: 'LIST' }],
          transformResponse: (response) => response,
        }),
        postPPI: builder.mutation({
          query: (body) => {
            return {
              url: '/ppis',
              method: 'POST',
              body,
            };
          },
          invalidatesTags: () => [{ type: 'ppi', id: 'LIST' }],
        }),
      };
    },
  });

export const { useFetchPPIListQuery, usePatchPPIMutation, usePostPPIMutation } =
  ppiApiSlice;
