import { useEffect, useState } from 'react';
import { useFetchPPIListQuery } from 'features/adminApi/endpoints/ppi';
import { convertHandleToTitle } from 'components/forms/Assets/lib';
import { PPI_OPTIONS } from 'components/forms/Initiative/lib.js';
import { PPIGroups } from 'lib/enums';

export function getPPIgroup(ppiHandle) {
  const ppiValues = PPI_OPTIONS.map((ppi) => {
    return ppi.value;
  });
  const groupName = ppiValues.includes(ppiHandle)
    ? PPIGroups.PROFILE_PPI
    : PPIGroups.OTHER_PPI;
  return groupName;
}

export const usePPIlist = () => {
  const { data = [], isLoading } = useFetchPPIListQuery();
  const [ppiList, setPPIlist] = useState([]);
  const response = data
    .map((itm) => {
      return {
        label: convertHandleToTitle(itm.name),
        value: itm.handle,
        group: getPPIgroup(itm.handle),
      };
    })
    .sort((item1, item2) => (item1.label > item2.label ? 1 : -1))
    .sort((item1, item2) => (item1.group > item2.group ? -1 : 1));

  useEffect(() => {
    if (!isLoading && data.length > 0 && ppiList.length === 0) {
      setPPIlist(response);
    }
  }, [data, response, isLoading]);

  return ppiList;
};

export default usePPIlist;
