/* eslint-disable max-statements */
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import {
  QuestionSetTypes,
  getFieldValueByFieldName,
  getQuestionSetByFieldName,
} from '../../RepeatableFieldsetUtils';
import { isTextOrHiddenType } from 'components/forms/utils';
import { SCREENER_FORM, SURVEY_FORM } from 'lib/layoutUtils';
import { ACTIVITY_GEAR_PPIS, getFieldLabel, ppiAnswerOptions } from '../../lib';
import { TagsInput } from 'components/fields';
import { AnswerOptions } from 'components/fields/AnswerOptionField';
import { Field, useFormikContext } from 'formik';
import {
  arrayOfObjects,
  initialValuesTypes,
  objectOrArray,
} from 'lib/propTypes';
import { renderOptionHeaders } from './utils';
import { errorMessage as helperText } from 'components/fields/AnswerOptionField/utils';
import { Toggle } from '@nike/eds';
import styles from '../../index.module.scss';

/* eslint-disable complexity */
export const TagsInputField = ({
  fieldId,
  fieldLabel,
  fieldName,
  fieldValue,
  placeholder,
  disabled,
  hasErrors,
  errorMessage,
  formState,
  initialValues,
  questionSet,
  errors,
  layoutType,
  onChange,
  isCorePPI,
  existingPPI,
  isApproved,
  setFieldValue,
}) => {
  let isTextOrHiddenAnsType;

  const { validateForm } = useFormikContext();

  const formStateQuestionSetField = getQuestionSetByFieldName(
    formState,
    fieldName
  );
  const valuesQuestionSetField = getQuestionSetByFieldName(
    questionSet,
    fieldName
  );
  const initialValuesQuestionSetField = getQuestionSetByFieldName(
    initialValues,
    fieldName
  );
  const ppiHandleSelected =
    getFieldValueByFieldName(
      formStateQuestionSetField,
      QuestionSetTypes.EXISTING_PPI
    )?.value ||
    getFieldValueByFieldName(
      valuesQuestionSetField,
      QuestionSetTypes.EXISTING_PPI
    )?.value;

  const questionType =
    getFieldValueByFieldName(
      formStateQuestionSetField,
      QuestionSetTypes.ANSWER_TYPE
    ) ||
    getFieldValueByFieldName(
      valuesQuestionSetField,
      QuestionSetTypes.ANSWER_TYPE
    );

  const isChoiceOrMultiType = ['choice', 'multi'].includes(questionType);

  if (layoutType === SURVEY_FORM) {
    const questionType =
      getFieldValueByFieldName(
        formStateQuestionSetField,
        QuestionSetTypes.ANSWER_TYPE
      ) ||
      getFieldValueByFieldName(
        valuesQuestionSetField,
        QuestionSetTypes.ANSWER_TYPE
      );
    isTextOrHiddenAnsType = isTextOrHiddenType(questionType);
  }

  const currentIdxInitialPPIValue = getFieldValueByFieldName(
    initialValuesQuestionSetField,
    QuestionSetTypes.EXISTING_PPI
  )?.value;

  const isInitialValueExistingPPI = !!ppiAnswerOptions(
    currentIdxInitialPPIValue
  );
  const isPPIHandleSelectedExistingPPI = !!ppiAnswerOptions(ppiHandleSelected);

  const initialAnswerOptionsExist =
    !isInitialValueExistingPPI &&
    getFieldValueByFieldName(
      initialValuesQuestionSetField,
      QuestionSetTypes.ANSWER_OPTIONS
    )?.length > 0;

  const formStateAnswerOptionsExist =
    !isPPIHandleSelectedExistingPPI &&
    getFieldValueByFieldName(
      formStateQuestionSetField,
      QuestionSetTypes.ANSWER_OPTIONS
    )?.length > 0;

  const answerOptionsExists =
    initialAnswerOptionsExist && formStateAnswerOptionsExist;

  const [isAdvancedModeEnabled, setIsAdvancedMode] =
    useState(answerOptionsExists);

  const showAnswerOptions = isAdvancedModeEnabled
    ? true
    : !isAdvancedModeEnabled && isPPIHandleSelectedExistingPPI;

  const handleAdvancedToggle = useCallback(() => {
    setIsAdvancedMode(!isAdvancedModeEnabled);
  }, [isAdvancedModeEnabled]);

  useEffect(() => {
    if (!showAnswerOptions) {
      validateForm();
    }
  }, [showAnswerOptions, fieldValue]);

  const headersFields = {
    layoutType,
    isCorePPI,
    isApproved,
    existingPPI,
    isTagsInputShown: !showAnswerOptions,
  };

  return (
    ppiHandleSelected !== ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL &&
    (isPPIHandleSelectedExistingPPI ? true : isChoiceOrMultiType) && (
      <FieldWrapper disabled={ disabled }>
        <Field
          key={ fieldId }
          name={ fieldName }
          placeholder={ placeholder || fieldName }
        >
          { ({ field }) => {
            return layoutType === SCREENER_FORM ||
              (layoutType === SURVEY_FORM && !isTextOrHiddenAnsType) ? (
                <>
                  { !isPPIHandleSelectedExistingPPI && (
                    <div className={ styles.advancedModeToggle }>
                      <Toggle
                        id={ `advanced-${ fieldName?.split('-')[1] }` }
                        name={ `advanced-${ fieldName?.split('-')[1] }` }
                        label={ 'Show option descriptions' }
                        onChange={ handleAdvancedToggle }
                        checked={ isAdvancedModeEnabled }
                      />
                    </div>
                  ) }
                  { renderOptionHeaders(headersFields) }
                  { showAnswerOptions ? (
                    <AnswerOptions
                      hasErrors={ hasErrors }
                      errorMessage={ errorMessage }
                      name={ fieldName }
                      id={ fieldName }
                      field={ field }
                      title={ getFieldLabel(fieldLabel || field.name) }
                      tags={ fieldValue || field.value }
                      onChange={ onChange }
                      existingPPI={ existingPPI }
                      isApproved={ isApproved }
                      disabled={ disabled }
                      formState={ formState }
                      errors={ errors }
                      layoutType={ layoutType }
                      ppiHandle={ ppiHandleSelected }
                    />
                  ) : (
                    <TagsInput
                      fullWidth={ true }
                      variant="outlined"
                      id={ fieldName }
                      name={ fieldName }
                      tags={ fieldValue || field.value }
                      disabled={ disabled }
                      title={ getFieldLabel(fieldLabel || field.name) }
                      helperText={ `${ helperText }. Type or paste options separated by a tab or line break.` }
                      required={ true }
                      errorMessage={ errorMessage }
                      onChange={ onChange }
                      separators={ ['Enter', 'Tab'] }
                      setFieldValue={ setFieldValue }
                    />
                  ) }
                </>
              ) : (
                !isTextOrHiddenAnsType && (
                  <TagsInput
                    hasErrors={ hasErrors }
                    errorMessage={ errorMessage }
                    disabled={ disabled }
                    name={ fieldName }
                    id={ fieldName }
                    fullWidth={ true }
                    variant="outlined"
                    title={ getFieldLabel(fieldLabel || field.name) }
                    helperText={ `${ helperText }. Type or paste options separated by a tab or line break.` }
                    tags={ fieldValue || field.value }
                    onChange={ onChange }
                    separators={ ['Enter', 'Tab'] }
                    required={ true }
                    setFieldValue={ setFieldValue }
                  />
                )
              );
          } }
        </Field>
      </FieldWrapper>
    )
  );
};

TagsInputField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: objectOrArray,
  existingPPI: PropTypes.string,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: arrayOfObjects,
  formState: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    initialValuesTypes.initialInquiryData,
  ]),
  hasErrors: PropTypes.bool,
  initialValues: objectOrArray,
  isApproved: PropTypes.bool,
  isCorePPI: PropTypes.bool,
  layoutType: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  questionSet: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    initialValuesTypes.initialInquiryData,
  ]),
  setFieldValue: PropTypes.func,
};
