import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import PPIForm from 'components/forms/PPI';
import { FormContainer } from 'components/FormContainer';
import { ViewMode } from 'lib/enums';
import { Text } from '@nike/eds';
import { usePostPPIMutation } from 'features/adminApi/endpoints/ppi';
import { useHistory } from 'react-router-dom';

const CreatePPI = ({ onSubmit }) => {
  const { addToast } = useToasts();
  const [postPPI, postPPIResult] = usePostPPIMutation();
  const history = useHistory();

  const handleCreatePPI = useCallback(
    async (values) => {
      try {
        const updatedValues = { ...values, uuid: window.crypto.randomUUID() };
        const response = await postPPI(updatedValues).unwrap();
        if (response && onSubmit) {
          await onSubmit(updatedValues);
        }
      } catch (error) {
        console.error('Error creating PPI:', error);
      }
    },
    [onSubmit, postPPI]
  );

  useEffect(() => {
    if (postPPIResult.error?.data?.errorMessage) {
      addToast(postPPIResult.error.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [postPPIResult.error]);

  useEffect(() => {
    if (postPPIResult.isSuccess) {
      addToast('PPI created successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      if (!onSubmit) history.push('/ppi');
    }
  }, [postPPIResult.isSuccess, history, onSubmit]);

  return (
    <FormContainer>
      <Text font={ 'title-1' } as={ 'h1' }>
        Create PPI
      </Text>
      <PPIForm
        onSubmit={ handleCreatePPI }
        disabled={ postPPIResult.isLoading }
        initialValues={{}}
        viewMode={ ViewMode.CREATE }
      />
    </FormContainer>
  );
};

CreatePPI.propTypes = {
  onSubmit: PropTypes.func,
};

export default CreatePPI;
