export const columns = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    filterable: true,
  },
  {
    field: 'handle',
    headerName: 'Handle',
    minWidth: 200,
    flex: 1,
    filterable: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 200,
    flex: 1,
    filterable: true,
  },
  {
    field: 'ppiType',
    headerName: 'PPI Type',
    minWidth: 200,
    flex: 1,
    filterable: true,
  },
];

export const actions = [
  {
    key: 'handle',
    url: '/ppi/',
    label: 'view',
    action: 'read',
    entity: 'ppi',
  },
  {
    key: 'handle',
    url: '/ppi/edit/',
    label: 'edit',
    action: 'edit',
    entity: 'ppi',
  },
];
