/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  QuestionSetTypes,
  getFieldByFieldName,
  getFieldValueByFieldName,
  getLastNonConditionalIdx,
  getQuestionSetByFieldName,
  getQuestionSetIndex,
  isCategoryField,
} from '../../RepeatableFieldsetUtils';
import { Field } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import { SelectField } from 'components/fields';
import styles from '../../index.module.scss';
import { isArray, isEmpty } from 'lodash';
import { TooltipContainer } from 'components/fields/SelectWithCriteria/styles';
import TooltipWrapper from 'components/TooltipWrapper';
import { Text } from '@nike/eds';
import CategoryContentToggle from '../CategoryContentToggle';
import {
  initialValuesTypes,
  objectOrArray,
  selectOptionPropType,
} from 'lib/propTypes';
import { filterPPI, getFieldLabel } from '../../lib';
import { useParams } from 'react-router-dom';
import { ViewMode } from 'lib/enums';
import { ConditionalSelectSubtitle } from './ConditionalSelectSubtitle';

export const InquirySelectField = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  placeholder,
  disabled,
  formState,
  fieldOptions,
  hasErrors,
  errorMessage,
  onChange,
  isCreatable,
  showCategoryContent,
  setShowCategoryContent,
  errors,
}) => {
  const { viewMode } = useParams();
  const isConditionalSelect = fieldName.includes(
    QuestionSetTypes.CONDITIONAL_OPTIONS
  );
  const isPPIField = fieldName.includes(QuestionSetTypes.EXISTING_PPI);
  const questionSetField = getQuestionSetByFieldName(formState, fieldName);
  const isConditionalToggled = getFieldValueByFieldName(
    questionSetField,
    QuestionSetTypes.CONDITIONAL_TOGGLE
  );
  const shouldRenderSelect =
    (isConditionalSelect && isConditionalToggled) ||
    [QuestionSetTypes.EXISTING_PPI, QuestionSetTypes.QUESTION_CATEGORY].some(
      (questionSetType) => fieldName.startsWith(questionSetType)
    );
  const currentIdx = getQuestionSetIndex(formState, fieldName);
  let conditionalOptions = [];
  let conditionalValues = {};
  let conditionalSelectionType = 'any';
  let isSelectDisabled = disabled;
  let isLastNonConditionalIdxChoiceQType = false;
  if (isConditionalToggled && isConditionalSelect) {
    const lastNonConditionalIdx = getLastNonConditionalIdx(
      currentIdx,
      formState
    );
    const lastNonConditionalIdxAnswerOptions = getFieldValueByFieldName(
      formState?.[lastNonConditionalIdx]?.value,
      QuestionSetTypes.ANSWER_OPTIONS
    );
    isLastNonConditionalIdxChoiceQType =
      getFieldValueByFieldName(
        formState?.[lastNonConditionalIdx]?.value,
        QuestionSetTypes.ANSWER_TYPE
      ) === 'choice';
    conditionalOptions = lastNonConditionalIdxAnswerOptions?.map((value) => ({
      label: typeof value === 'string' ? value : value.label || value.name,
      value: typeof value === 'string' ? value : value.value,
    }));
    isSelectDisabled =
      (fieldName.startsWith(QuestionSetTypes.EXISTING_PPI) &&
        formState?.[currentIdx]?.isUsedInConditional) ||
      disabled;
    const conditionalField = getFieldByFieldName(
      formState?.[currentIdx]?.value,
      QuestionSetTypes.CONDITIONAL_OPTIONS
    );
    conditionalValues = conditionalField?.value || {};
    conditionalSelectionType = isLastNonConditionalIdxChoiceQType
      ? 'any'
      : conditionalField?.selectionType;
  }

  const options = isConditionalSelect
    ? isPPIField
      ? conditionalOptions?.filter(filterPPI)
      : conditionalOptions
    : isPPIField
      ? fieldOptions?.filter(filterPPI)
      : fieldOptions;

  const categoryList =
    !isEmpty(formState) &&
    formState.map(
      (question) =>
        getFieldValueByFieldName(
          question.value,
          QuestionSetTypes.QUESTION_CATEGORY
        )?.value ?? null
    );

  const isRepeatedCategory = isArray(categoryList)
    ? categoryList.slice(0, currentIdx).includes(categoryList[currentIdx])
    : false;

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasCategoryError = Object.keys(errors).some((key) =>
        isCategoryField(key.split('-')[0])
      );
      if (
        !isRepeatedCategory &&
        hasCategoryError &&
        viewMode !== ViewMode.CREATE
      ) {
        setShowCategoryContent(true);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [errors, isRepeatedCategory]);

  const [selectionType, setSelectionType] = useState('any');

  useEffect(() => {
    setSelectionType(conditionalSelectionType);
  }, [conditionalSelectionType]);

  useEffect(() => {
    if (isConditionalToggled && isConditionalSelect) {
      if (isLastNonConditionalIdxChoiceQType && selectionType !== 'any') {
        onChange({
          target: {
            name: `selectionType-${ fieldName?.split('-')[1] }`,
            value: 'any',
          },
        });
      }
    }
  }, [
    isConditionalToggled,
    isConditionalSelect,
    selectionType,
    isLastNonConditionalIdxChoiceQType,
  ]);

  const subtitle = isConditionalSelect && (
    <ConditionalSelectSubtitle
      fieldName={ fieldName }
      selectionType={ selectionType }
      onChange={ onChange }
      disabled={ isLastNonConditionalIdxChoiceQType }
    />
  );

  return (
    <FieldWrapper disabled={ disabled }>
      <Field
        key={ fieldId }
        name={ fieldName }
        placeholder={ placeholder || fieldName }
      >
        { ({ field }) => {
          return (
            shouldRenderSelect && (
              <TooltipContainer>
                <TooltipWrapper
                  active={ isSelectDisabled }
                  message={
                    <Text
                      font="subtitle-2"
                      className="eds-spacing--mb-4"
                      style={{ wordBreak: 'break-word' }}
                    >
                      {
                        '⚠️ PPI select is restricted as options are used in a conditional question.'
                      }
                    </Text>
                  }
                  children={
                    <div className={ styles.selectFieldWrapper }>
                      <SelectField
                        hasErrors={ !!hasErrors }
                        errorMessage={ errorMessage }
                        disabled={ isSelectDisabled }
                        name={ fieldName }
                        id={ fieldName }
                        multiple={ !!isConditionalSelect }
                        label={ getFieldLabel(fieldLabel || field.name) }
                        value={
                          fieldValue ||
                          field.value ||
                          (isConditionalSelect ? conditionalValues : {})
                        }
                        options={ options }
                        isCreatable={ isCreatable }
                        onChange={ onChange }
                        subtitle={ subtitle ?? '' }
                        placeholder={
                          isConditionalSelect ? 'Select answers' : ''
                        }
                        required={ true }
                      />
                      { field?.name
                        .split('-')[0]
                        .includes(QuestionSetTypes.QUESTION_CATEGORY) && (
                        <CategoryContentToggle
                          showCategoryContent={ showCategoryContent }
                          setShowCategoryContent={ setShowCategoryContent }
                          isRepeatedCategory={ isRepeatedCategory }
                          showToggle={ !!(fieldValue || field.value) }
                        />
                      ) }
                    </div>
                  }
                />
              </TooltipContainer>
            )
          );
        } }
      </Field>
    </FieldWrapper>
  );
};

InquirySelectField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: objectOrArray,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldOptions: PropTypes.arrayOf(selectOptionPropType),
  fieldValue: PropTypes.oneOfType([
    selectOptionPropType,
    PropTypes.arrayOf(selectOptionPropType),
    PropTypes.shape({}),
  ]),
  formState: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    initialValuesTypes.initialInquiryData,
  ]),
  hasErrors: PropTypes.bool,
  isCreatable: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  setShowCategoryContent: PropTypes.func,
  showCategoryContent: PropTypes.bool,
};
